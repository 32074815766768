import {
  Accessor,
  ErrorBoundary,
  Match,
  Setter,
  Show,
  Switch,
  createEffect,
  createSignal,
  onMount,
} from "solid-js";
import { JSX } from "solid-js/jsx-runtime";
import { clientRepo } from "~/server/apis/client_repo";
import { EditIcon } from "~/assets/svg_icons/edit_icon";
import { HubbleError } from "../error";
import { setCookie, getCookie } from "~/utils/client_cookie";
import { Cookie, Mode } from "~/types";
import { ThreeDotLoader } from "~/widgets/button";
import { useIsRouting, useNavigate } from "@solidjs/router";
import { APIError } from "~/utils/fetch";
import { RNREvent, rnrEventManager } from "~/data/events";
import { captureErrorInSentry } from "~/utils/third_party/sentry";
import { setIsFreshLogin } from "~/components/brand_l2/header";
import { AuthMethod } from "~/server/types/client";
import { getRequestEvent } from "solid-js/web";
import { config } from "~/data/config";
import { removeWhitespace } from "~/utils/common";

type ErrorState = {
  phoneNumber?: string;
  email?: string;
  otp?: string;
  emailOrPhoneNumber?: string;
};

type LoginFormProps = {
  // client id and secret can be undefined for squid login
  clientSecret: () => string | undefined;
  clientId: () => string | undefined;
  authMethod: () => AuthMethod;
  mode: () => Mode;
  placeholderText?: string;
};

const LoginForm = (props: LoginFormProps) => {
  const navigate = useNavigate();
  const [emailOrPhoneNumber, setEmailOrPhoneNumber] = createSignal("");
  const [otp, setOTP] = createSignal<string | null>(null);
  const [otpToken, setOTPToken] = createSignal<string | null>(null);
  const [errorState, setErrorState] = createSignal<ErrorState>({});
  const [loading, setLoading] = createSignal({
    generateOTP: false,
    verifyOTP: false,
    verifyCode: false,
  });
  const isRouting = useIsRouting();
  const [resetCountdown, setResetCountdown] = createSignal(30);
  let timer: NodeJS.Timeout;
  const requestEvent = getRequestEvent();

  let inputRef: HTMLInputElement | undefined;

  function identifyInput(input: string) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;

    if (emailRegex.test(input)) {
      return "email";
    }
    if (phoneRegex.test(input)) {
      return "phoneNumber";
    }

    return null;
  }

  createEffect(async () => {
    if (otpToken() !== null && (otp() ?? "").length === 6) {
      await verifyOTP();
    }
  });

  onMount(() => {
    inputRef?.focus();
  });

  const redirectToWeb = (url: string) => {
    window.open(url, "_blank");
  };

  const verifyCode = async () => {
    setLoading((prevLoadingState) => ({
      ...prevLoadingState,
      verifyCode: true,
    }));

    try {
      const verifyCodeResponse = await clientRepo.verifyPartnerCode(
        {
          clientId: props.clientId()!,
          clientSecret: props.clientSecret()!,
        },
        {
          code: emailOrPhoneNumber(),
        },
        {
          isUnauthenticated: true,
        }
      );

      if (verifyCodeResponse.sessionId) {
        setCookie({
          key: Cookie.SessionId,
          value: verifyCodeResponse.sessionId,
          expiryInMinutes: config.sesssionDurationInMinutes.rnr,
        });
        setIsFreshLogin(true);
        navigate("/");
      } else {
        setErrorState({
          emailOrPhoneNumber:
            "An unexpected error occurred. Please try again later.",
        });
      }
    } catch (error: unknown) {
      if (error instanceof APIError) {
        setErrorState({ emailOrPhoneNumber: error.message });
      } else {
        setErrorState({
          emailOrPhoneNumber:
            "An unexpected error occurred. Please try again later.",
        });
      }
    } finally {
      setLoading({
        generateOTP: false,
        verifyOTP: false,
        verifyCode: false,
      });
    }
  };

  const verifyOTP = async () => {
    setLoading((prevLoadingState) => ({
      ...prevLoadingState,
      verifyOTP: true,
    }));
    const inputType = identifyInput((emailOrPhoneNumber() ?? "").trim());
    try {
      const verifyOTPResponse = await clientRepo.verifyPartnerOTP(
        {
          clientId: getCookie(Cookie.ClientId) ?? props.clientId()!,
          clientSecret: getCookie(Cookie.ClientSecret) ?? props.clientSecret()!,
        },
        {
          [inputType!]: emailOrPhoneNumber(),
          otp: otp() ?? "",
          otpToken: otpToken() ?? "",
        },
        {
          isUnauthenticated: true,
          throwAuthError: true,
        }
      );

      if (verifyOTPResponse.sessionId) {
        rnrEventManager.sendEvent(RNREvent.VERIFY_OTP_SUCCESS);
        setCookie({
          key: Cookie.SessionId,
          value: verifyOTPResponse.sessionId,
          expiryInMinutes:
            props.mode() === "squid"
              ? config.sesssionDurationInMinutes.squid
              : config.sesssionDurationInMinutes.rnr, // keeping rnr session for 24 hours
        });
        setIsFreshLogin(true);
        if (props.mode() === "squid") {
          navigate("/catalogue", {
            state: {
              from: "login",
            },
          });
        } else {
          navigate("/");
        }
      } else {
        setErrorState({
          otp: "An unexpected error occurred. Please try again later.",
        });
        resetTimer();
      }
    } catch (error: any) {
      if (error instanceof APIError && error.code === 401) {
        setErrorState({ otp: "Invalid OTP. Please try again. " });
      } else {
        setErrorState({
          otp: error?.message,
        });
      }
      rnrEventManager.sendEvent(RNREvent.VERIFY_OTP_FAILURE, {
        error: error,
      });
      resetTimer();
    } finally {
      setOTP("");
      setLoading({
        generateOTP: false,
        verifyOTP: false,
        verifyCode: false,
      });
    }
  };

  const handleOTPChange = (event: Event) => {
    const value = (event.currentTarget as HTMLInputElement).value;
    setErrorState({});
    setOTP(value);
  };

  const resendOtp = () => {
    setOTP(null);
    const inputType = identifyInput((emailOrPhoneNumber() ?? "").trim());
    if (inputType) {
      generateOTP(inputType);
    }
  };

  const resetTimer = () => {
    clearInterval(timer);
    setResetCountdown(0);
  };

  const startCountdown = () => {
    setResetCountdown(30);
    timer = setInterval(() => {
      setResetCountdown((prev) => {
        if (prev === 1) {
          clearInterval(timer);
          return prev - 1;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleSubmit: JSX.EventHandler<HTMLFormElement, Event> = (event) => {
    event.preventDefault();

    if (props.authMethod() === "CODE") {
      if (
        emailOrPhoneNumber().length >= 15 &&
        emailOrPhoneNumber().length <= 24
      ) {
        verifyCode();
        return;
      } else {
        setErrorState({
          emailOrPhoneNumber: "Please enter a valid code.",
        });
      }
    } else {
      if (otpToken() !== null && (otp() ?? "").length === 6) {
        verifyOTP();
        return;
      }

      const inputType = identifyInput((emailOrPhoneNumber() ?? "").trim());
      if (inputType) {
        generateOTP(inputType);
      } else {
        setErrorState({
          emailOrPhoneNumber:
            "Please enter a valid email or a 10-digit phone number.",
        });
      }
    }
  };

  const handleInput = (e: InputEvent, setValue: Setter<string>) => {
    const target = e.currentTarget as HTMLInputElement;
    setValue(removeWhitespace(target.value));
    setErrorState({});
  };

  const generateOTP = async (inputType: "email" | "phoneNumber") => {
    setLoading((prevLoadingState) => ({
      ...prevLoadingState,
      generateOTP: true,
    }));
    setErrorState({});
    try {
      if (props.mode() === "squid") {
        const response = await clientRepo.generateProcurementOtp({
          [inputType]: emailOrPhoneNumber(),
        });
        if (response) {
          setOTPToken(response.otpToken);

          startCountdown();
          setLoading((prevLoadingState) => ({
            ...prevLoadingState,
            generateOTP: false,
          }));
          rnrEventManager.sendEvent(RNREvent.GENERATE_OTP_SUCCESS);

          setCookie({
            key: Cookie.ClientId,
            value: response.clientId,
            expiryInDays: 1,
          });
          setCookie({
            key: Cookie.ClientSecret,
            value: response.clientId,
            expiryInDays: 1,
          });
        }
      } else {
        const response = await clientRepo.generatePartnerOTP(
          {
            clientId: props.clientId()!,
            clientSecret: props.clientSecret()!,
          },
          {
            [inputType]: emailOrPhoneNumber(),
          },
          {
            isUnauthenticated: true,
          }
        );

        if (response.otpToken) {
          setOTPToken(response.otpToken);
          startCountdown();
          setLoading((prevLoadingState) => ({
            ...prevLoadingState,
            generateOTP: false,
          }));
          rnrEventManager.sendEvent(RNREvent.GENERATE_OTP_SUCCESS);
        }
      }
    } catch (error) {
      if (error instanceof APIError) {
        setErrorState({
          emailOrPhoneNumber: error.message,
        });
        rnrEventManager.sendEvent(
          error.code === 429
            ? RNREvent.GENERATE_OTP_LIMIT_FAILURE
            : RNREvent.GENERATE_OTP_FAILURE,
          {
            error: error,
          }
        );
      } else {
        setErrorState({
          emailOrPhoneNumber: "Something went wrong. Please try again later.",
        });
      }
    } finally {
      setLoading({
        generateOTP: false,
        verifyOTP: false,
        verifyCode: false,
      });
    }
  };

  const getInputPlaceholder = (authMethod: AuthMethod): string => {
    if (props.placeholderText) {
      return props.placeholderText;
    }
    return authMethod === "EMAIL"
      ? "Official email ID"
      : authMethod === "PHONE"
        ? "10 digit phone no."
        : authMethod === "CODE"
          ? ""
          : "Official email ID or 10 digit phone no.";
  };

  return (
    <ErrorBoundary
      fallback={(err) => {
        captureErrorInSentry(err);
        return <HubbleError errorMessage={err.message} />;
      }}
    >
      <form
        class="w-full max-w-[400px]"
        onSubmit={handleSubmit}
        classList={{
          "px-4 sm:px-5": props.mode() === "squid",
          "px-5": props.mode() !== "squid",
        }}
      >
        <Show when={otpToken() === null && otp() === null} fallback={<></>}>
          <CollectIdentifer />
        </Show>

        <Show when={(otpToken() ?? "").length > 0} fallback={<></>}>
          <CollectOtp />
        </Show>

        <Show
          when={
            otpToken() === null &&
            otp() === null &&
            props.authMethod() !== "CODE"
          }
        >
          <TncAndPrivacyPolicyText />
        </Show>
        <SubmitButton />
      </form>
    </ErrorBoundary>
  );

  function CollectIdentifer() {
    return (
      <>
        <div
          class="min-w-sm mb-4 flex w-full flex-col"
          classList={{
            "gap-3": props.mode() !== "squid",
            "gap-2 sm:gap-3": props.mode() === "squid",
          }}
        >
          <label
            for="emailOrPhoneNumber"
            class=" text-textDark"
            classList={{
              "text-bold": props.mode() !== "squid",
              "text-mediumBold sm:text-bold": props.mode() === "squid",
            }}
          >
            <Switch fallback={<>Email ID / phone no.</>}>
              <Match when={props.authMethod() === "EMAIL"}>Email ID</Match>
              <Match when={props.authMethod() === "PHONE"}>Phone number</Match>
              <Match when={props.authMethod() === "CODE"}>
                <div class="text-center text-f12Bold uppercase text-textNormal">
                  Enter Code
                </div>
              </Match>
            </Switch>
          </label>
          <input
            type="text"
            id="emailOrPhoneNumber"
            value={emailOrPhoneNumber() ?? ""}
            ref={inputRef}
            title={getInputPlaceholder(props.authMethod())}
            placeholder={getInputPlaceholder(props.authMethod())}
            onInput={(e) => handleInput(e, setEmailOrPhoneNumber)}
            required
            class={`text-sm focus:border-1 block w-full rounded-xl border px-4  py-3 focus:border-baseDark focus:ring-baseDark  disabled:pointer-events-none disabled:opacity-50 ${
              (errorState().email?.length ?? 0) > 0 ||
              (errorState().phoneNumber?.length ?? 0) > 0 ||
              (errorState().emailOrPhoneNumber?.length ?? 0) > 0
                ? "border-errorDark"
                : "border-gray-200"
            }`}
            classList={{
              "placeholder:text-medium": props.mode() !== "squid",
              "placeholder:text-normal sm:placeholder:text-medium":
                props.mode() === "squid",
            }}
          />
          <Show
            when={
              (errorState().email?.length ?? 0) > 0 ||
              (errorState().phoneNumber?.length ?? 0) > 0 ||
              (errorState().emailOrPhoneNumber?.length ?? 0) > 0
            }
          >
            <p
              class="mt-1 text-errorDark"
              classList={{
                "text-f12": props.mode() !== "squid",
                "text-f12Bold sm:text-f12": props.mode() === "squid",
              }}
            >
              {errorState().email ||
                errorState().phoneNumber ||
                errorState().emailOrPhoneNumber}
            </p>
          </Show>
        </div>
      </>
    );
  }

  function CollectOtp() {
    return (
      <>
        <h2
          class="text-textDark"
          classList={{
            "text-bold": props.mode() !== "squid",
            "text-h4 mb-2 sm:text-bold sm:mb-0": props.mode() === "squid",
          }}
        >
          Verify OTP
        </h2>
        <div class="flex items-center gap-1 text-medium text-textNormal">
          Sent to{" "}
          <div
            class="flex cursor-pointer gap-1"
            onClick={() => {
              setOTPToken(null);
              setOTP(null);
              setResetCountdown(30);
            }}
          >
            <span class="underline">{emailOrPhoneNumber()}</span>
            <EditIcon size={"18"} />
          </div>
        </div>
        <OtpInput
          otp={otp}
          setOTP={setOTP}
          handleOTPChange={handleOTPChange}
          isError={(errorState().otp?.length ?? 0) > 0}
          removeFocus={
            loading().generateOTP || loading().verifyOTP || isRouting()
          }
          mode={props.mode}
        />
        <Show when={errorState().otp?.length ?? 0 > 0}>
          <p
            class="mt-2 text-errorDark"
            classList={{
              "text-f12": props.mode() !== "squid",
              "text-f12Bold sm:text-f12": props.mode() === "squid",
            }}
          >
            {errorState().otp}
          </p>
        </Show>
        <Show when={resetCountdown() > 0} fallback={<></>}>
          <p class="mt-3 text-medium text-textNormal">
            Resend OTP in {resetCountdown()}
          </p>
        </Show>
        <Show when={resetCountdown() === 0}>
          <div
            class="mt-3"
            onClick={() => {
              resendOtp();
              setResetCountdown(30);
            }}
          >
            <p class={"text-medium text-textNormal"}>
              {"Didn't get OTP? "}
              <span class={"cursor-pointer text-textDark underline"}>
                Resend OTP
              </span>
            </p>
          </div>
        </Show>
      </>
    );
  }

  function TncAndPrivacyPolicyText() {
    return (
      <p
        class="text-medium "
        classList={{
          "text-textNormal": props.mode() !== "squid",
          "text-black sm:text-textNormal": props.mode() === "squid",
        }}
      >
        {"By continuing, I agree to the "}
        <span
          class="cursor-pointer text-textDark underline"
          onClick={() => {
            redirectToWeb("https://www.myhubble.money/terms-and-conditions");
          }}
        >
          terms & conditions
        </span>
        {" and "}
        <span
          class="cursor-pointer text-textDark underline"
          onClick={() => {
            redirectToWeb("https://www.myhubble.money/privacy-policy");
          }}
        >
          privacy policy
        </span>
      </p>
    );
  }

  function SubmitButton() {
    return (
      <button
        type="submit"
        class={`text-sm button-filled my-4 mb-0 flex h-12 w-full items-center justify-center bg-basePrimaryDark py-3 text-baseTertiaryLight disabled:cursor-not-allowed disabled:py-4 disabled:text-baseSecondaryMedium disabled:opacity-40`}
        disabled={
          loading().generateOTP ||
          loading().verifyOTP ||
          loading().verifyCode ||
          isRouting()
        }
        classList={{
          "rounded-full": props.mode() !== "squid",
          "rounded-lg sm:rounded-full": props.mode() === "squid",
        }}
      >
        {loading().generateOTP ||
        loading().verifyOTP ||
        loading().verifyCode ||
        isRouting() ? (
          <ThreeDotLoader color={"#fff"} />
        ) : (
          <p class="text-f16Bold">
            <Switch fallback={"Continue"}>
              <Match when={props.authMethod() === "CODE"}>Redeem</Match>
            </Switch>
          </p>
        )}
      </button>
    );
  }
};

export default LoginForm;

export function OtpInput(props: {
  otp: Accessor<string | null>;
  setOTP: Setter<string | null>;
  handleOTPChange: (e: Event) => void;
  isError: boolean;
  removeFocus: boolean;
  isTypeAlphaNumeric?: boolean;
  mode?: () => string;
}) {
  const [hasFocus, setHasFocus] = createSignal<boolean>(false);

  let otpInput: HTMLInputElement | undefined;

  createEffect(async () => {
    otpInput?.focus();
  });

  createEffect(() => {
    if (props.removeFocus) {
      otpInput?.blur();
    } else {
      otpInput?.focus();
    }
  });

  const handleDivClick = () => {
    otpInput?.focus();
    setHasFocus(true);
  };

  const handlePaste = (event: ClipboardEvent) => {
    event.preventDefault();
    const pasteData = event.clipboardData?.getData("text");
    if (pasteData) {
      let sanitizedData = pasteData;
      if (!props.isTypeAlphaNumeric) {
        sanitizedData = pasteData.replace(/\D/g, "");
      }
      sanitizedData = sanitizedData.slice(0, 6);
      props.setOTP(sanitizedData);

      const inputEvent = new InputEvent("input", {
        bubbles: true,
        data: sanitizedData,
      });
      Object.defineProperty(inputEvent, "target", { value: otpInput });
      Object.defineProperty(inputEvent, "currentTarget", { value: otpInput });
      props.handleOTPChange(inputEvent);
    }
  };

  createEffect(async () => {
    // autoReadOtp();  // This is not supported in BE, can uncomment once supported
  });

  const autoReadOtp = async () => {
    if ("OTPCredential" in window) {
      console.log("OTP autofill supported");
      const ac = new AbortController();

      setTimeout(() => {
        ac.abort("OTP autofill aborted after 30 seconds.");
      }, 30 * 1000);

      let options: CredentialRequestOptions = {
        otp: { transport: ["sms"] },
        signal: ac.signal,
      };

      try {
        const otp = await window.navigator["credentials"].get(options);
        if (otp && otp.code) {
          props.setOTP(otp.code);
          const inputEvent = new Event("input", { bubbles: true });
          Object.assign(inputEvent, {
            currentTarget: { value: otp.code },
          });
          props.handleOTPChange(inputEvent);
        }
      } catch (error) {
        ac.abort(`OTP autofill aborted due to error ${error}`);
        console.error(error);
      }
    }
  };

  return (
    <div
      class="relative flex w-full max-w-sm flex-col"
      classList={{
        "mt-3": props.mode && props.mode() !== "squid",
        "mt-4 sm:mt-3": props.mode && props.mode() === "squid",
      }}
    >
      <input
        ref={otpInput}
        class="absolute h-full w-full bg-transparent text-transparent  caret-transparent "
        style="z-index: 1; -webkit-user-select: text;"
        type="text"
        autocomplete="one-time-code"
        inputmode={props.isTypeAlphaNumeric ? "text" : "numeric"}
        value={props.otp() ?? ""}
        required
        maxLength={6}
        placeholder=""
        onInput={(event) => props.handleOTPChange(event)}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        onPaste={handlePaste}
      />
      <div class="flex w-full items-center justify-around space-x-2">
        {[...Array(6)].map((_, index) => (
          <div
            class={`flex h-12 max-w-16 flex-1 cursor-text items-center justify-center rounded-[12px] border dark:text-bold dark:text-baseTertiaryLight`}
            onClick={handleDivClick}
            classList={{
              "border-baseDark dark:border-baseTertiaryLight":
                index == props.otp()?.length && !props.removeFocus,
              "border-2": index == props.otp()?.length && !props.removeFocus,
              "border-baseSecondaryLight":
                index != props.otp()?.length && !props.removeFocus,
              "border-errorDark": props.isError,
              "dark:border-baseTertiaryLight":
                index < (props.otp()?.length ?? 0),
            }}
          >
            {(props.otp() ?? "")[index] ? (
              (props.otp() ?? "")[index]
            ) : index == (props.otp() ?? "")?.length && hasFocus() ? (
              <div
                class={`h-3 w-0.5 animate-[blink_1s_infinite] bg-baseDark dark:bg-baseTertiaryLight`}
              />
            ) : (
              <div class="h-1 w-1 rounded-full bg-baseSecondaryLight" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
